import React, { useEffect, useState } from 'react'

const Scripts = () => {
    const [active, setActive] = useState(false)

    const events = [
        'keypress', 'mousemove', 'touchstart', 'touchmove', 'click', 'scroll'
    ]

    // const googleTag = () => {
    //     const mainScript = document.createElement('script')
    //     mainScript.setAttribute('id', "gtag-init")
    //     mainScript.setAttribute('async', 'true')
    //     mainScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-1X6282J7KK')
    //     if (!document.getElementById('gtag-init'))
    //         document.head.appendChild(mainScript)

    //     const script = document.createElement('script')
    //     script.setAttribute('id', "gtag-script")
    //     script.innerHTML = `window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());

    //     gtag('config', 'G-1X6282J7KK');`
    //     if (!document.getElementById('gtag-script'))
    //         document.head.appendChild(script)
    // }

    // const universalAnalytics = () => {
    //     const mainScript = document.createElement('script')
    //     mainScript.setAttribute('id', "ua-init")
    //     mainScript.setAttribute('async', 'true')
    //     mainScript.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=UA-253208571-1")
    //     if (!document.getElementById('ua-init'))
    //         document.head.appendChild(mainScript)

    //     const script = document.createElement('script')
    //     script.setAttribute('id', "ua-script")
    //     script.innerHTML = `window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());

    //     gtag('config', 'UA-253208571-1');`
    //     if (!document.getElementById('ua-script'))
    //         document.head.appendChild(script)
    // }

    const loadHubSpotScript = () => {
        if (!document.getElementById('hs-script-loader')) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'hs-script-loader';
            script.async = true;
            script.defer = true;
            script.src = '//js.hs-scripts.com/48799727.js';
            document.head.appendChild(script);
        }
    };

    const gtm = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "gtm")
        script.async = true
        script.defer = true;
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-52DB9VC');`
        if (!document.getElementById('gtm'))
            document.head.appendChild(script)
    }

    const loadDigiQAnalytics = () => {
        if (!document.getElementById('digiq-analytics')) {
            const script = document.createElement('script');
            script.setAttribute('id', 'digiq-analytics');
            script.async = true;
            script.defer = true;
            script.innerHTML = `
                var _paq = window._paq = window._paq || [];
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function() {
                    var u="//recommendation-analytics.digitup.in/";
                    _paq.push(['setTrackerUrl', u+'js/tracker.php']);
                    _paq.push(['setSiteId', '1']);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src=u+'js/tracker.php'; s.parentNode.insertBefore(g,s);
                })();
            `;
            document.head.appendChild(script);
        }
    };

    // const cookieBot = () => {
    //     const script = document.createElement('script');
    //     script.id = 'cookieBot';
    //     script.src = 'https://consent.cookiebot.com/uc.js';
    //     script.setAttribute('data-cbid', '14b2f5cc-268c-4689-94ab-ca5f1bd5fe34');
    //     script.setAttribute('data-blockingmode', 'auto');
    //     script.type = 'text/javascript';
    //     if (!document.getElementById('cookieBot'))
    //         document.head.appendChild(script);
    // };

    const loadCookieScript = () => {
        const script = document.createElement('script');
        script.src = '//cdn.cookie-script.com/s/4474c56e5954004bae8dc4363240852a.js';
        script.async = true;
        script.defer = true;
        script.setAttribute('charset', 'UTF-8');
        document.head.appendChild(script);
    };

    const handleEvent = () => {
        setActive(true)
        // googleTag()
        // universalAnalytics()
        gtm()
        loadDigiQAnalytics()
        loadCookieScript()
        loadHubSpotScript();
        // cookieBot()
        // zendesk()
        // zendeskFormHide()
        events.forEach((event) => window.removeEventListener(event, handleEvent))
    }

    useEffect(() => {
        events.forEach((event) => window.addEventListener(event, handleEvent))
        const timeoutId = setTimeout(() => {
            if (!active) {
                handleEvent();
            }
        }, 6000); // 12 seconds

        return () => {
            clearTimeout(timeoutId);
            events.forEach((event) => window.removeEventListener(event, handleEvent));
        };
    }, [])

    return (
        <></>
    )
}

export default Scripts